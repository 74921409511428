.image-slider-loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image-loading,
.thumbnail-loading {
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

.main-image-loading {
  width: 100%;
  height: 22rem; /* Adjust height as needed */
  margin-bottom: 16px;
}

.thumbnail-container-loading {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.thumbnail-loading {
  width: 80px;
  height: 80px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
