body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-neutral-100 scrollbar scrollbar-thumb-slate-800 scrollbar-track-neutral-200
}

code {
  font-family: 'Josefin Sans', sans-serif;
}
.grid-item {
  /* Your existing styles for individual grid items */
  position: relative;
}


.nav-link:hover {
  font-weight: 400;
}


.overlay-text {
  /* Styles for the text overlay */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity and color as per your preference */
  color: #fff; /* Text color */
  font-size: 18px; /* Text font size */
  opacity: 0; /* Initially, the text is hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth transition effect */
}

.grid-item:hover .overlay-text {
  cursor: pointer;
  opacity: 1; /* Show the text on hover */
}
.grid-item:hover{
  @apply grayscale scale-105 duration-150
  transition-all
  
}
.selected {
  /* Add your desired styling to highlight the selected category here */
  border-bottom: 1px solid #000; /* Example border color is black (#000), you can change it to match your design */
}
.dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 0.5em;
  height: 0.5em;
  @apply bg-slate-100;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;

