.loading-card {
  width: 20rem; /* matches the w-80 class in DemiCard */
  height: 26rem;
  background-color: #f5f5f5; /* light grey background */
  border: 1px solid #cbd5e1; /* matches the border-slate-400 */
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  margin: 16px auto;
}

.loading-image {
  height: 66.6%; /* matches the h-2/3 class */
  border-radius: 8px;
  margin-bottom: 16px;
}

.loading-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
}

.loading-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.loading-text {
  width: 70%;
  height: 20px;
  margin-bottom: 8px;
}

.loading-price {
  width: 50%;
  height: 16px;
}

.loading-size {
  width: 30%;
  height: 16px;
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
